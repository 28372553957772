import React from "react";
import { isNullOrEmpty } from "../utils/objects";

type Props = {
    target?: any,
    children?: React.ReactNode,
}

export function NothingInfo(props: Props) {
    const { target, children } = props;
    const nothing = isNullOrEmpty(target);
    if (!nothing) {
        return null;
    }
    return (
        <>
            {children}
        </>
    )
}

import React from 'react';
import useReactRouter from 'use-react-router';
import useCourseFormSetting from '../api/use-course-form-setting';
import ReservationForm from '../components/ReservationForm';
import ReservationThanks from '../components/ReservationThanks';
import { Header } from '../components/Shared';
import { Field, FormResponse } from '../core/types/reservation-form-types';
import { createDateTime, DateTimeFormat } from '../core/types/reservation-types';
import useStorage from '../hooks/use-storage';
import useUserScript from '../hooks/use-user-script';

type PageParams = {
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  action: 'form' | 'thanks',
}

function createDefaultFormResponse(): FormResponse {
  return {
    fields: [],
  };
}

function getStorageKey(workspaceUid: string) {
  return `booknow_${workspaceUid}`;
}

export function claerStorage(workspaceUid: string) {
  sessionStorage.removeItem(getStorageKey(workspaceUid));
}

export default function FormPage() {
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid, courseUid, action } = match.params;

  const searchParams = new URL(window.location.href).searchParams;
  const dateParam = searchParams.get('date');
  const timeParam = searchParams.get('time');
  const dateTime = createDateTime(`${dateParam} ${timeParam}` as DateTimeFormat);

  const [formResponse, setFormResponse] = useStorage(getStorageKey(workspaceUid), createDefaultFormResponse(), 'session');

  const { workspace, workspaceSetting, shop, course, formSetting, isLoadingCourseFormSetting } = useCourseFormSetting(workspaceUid, shopUid || '', courseUid || '');
  useUserScript(workspaceSetting);

  const handleChangeValue = (field: Field, newValues: string[]) => {
    const { fields } = formResponse;
    const newFieldResponses = [...fields];
    const targetIndex = fields.findIndex((f) => f.uid == field.uid);
    if (targetIndex == -1) {
      newFieldResponses.push({
        uid: field.uid,
        values: newValues,
      });
    } else {
      newFieldResponses[targetIndex].values = newValues;
    }
    setFormResponse({ fields: newFieldResponses })
  };

  return (
    isLoadingCourseFormSetting ? 
    <>
      <>Loading...</>
    </>
    :
      <>
        <Header h1={`${workspace?.name} / ${shop?.name}`} h2={course?.name} style={{backgroundColor: '#c21632', color: '#fff'}}></Header>
        {action == 'form' ?
          <ReservationForm
            workspace={workspace}
            shop={shop}
            course={course}
            dateTime={dateTime}
            formSetting={formSetting}
            formResponse={formResponse}
            onChangeValue={handleChangeValue}
          />
          :
          <ReservationThanks
            workspace={workspace}
            shop={shop}
            course={course}
            dateTime={dateTime}
            formSetting={formSetting}
            formResponse={formResponse}
          />
        }
      </>
    );
}

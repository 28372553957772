/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from "react";
import { IDate, toDate, toDayjs } from "../../core/types/reservation-types";
import { range } from "../../utils/numbers";
import { commonStyles } from '../styles';
import { createCalendarLines } from "./calendar-helper";

type Props = {
    floating: boolean,
    currentDate: IDate,
    onChangeCurrentDate: (newDate: IDate) => void,
}

const styles = {
    container: css({
        padding: '5px',
        width: '300px',
        border: 'solid 1px #aaa',
        background: '#fff',
    }),
    floating: css({
        position: 'absolute',
        top: '0px',
        left: 'calc(50% - 150px)',
    }),
    toolbar: css({
        padding: '5px',
        textAlign: 'center',
        fontSize: '14px',
    }),
    toolbarButton: css({
        fontSize: '12px',
        margin: '0px 5px',
    }),
    toolbarSelect: css({
        fontSize: '16px',
        margin: '0px 5px',
        border: 'solid 0px #000',
    }),
    table: css({
        width: '100%',
    }),
    cell: css({
        padding: '5px',
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: '#f0f0f0',
        },
    }),
    currentDateCell: css({
        background: '#def',
    }),
}

export function Calendar(props: Props) {
    const { currentDate, onChangeCurrentDate, floating } = props;

    const currentDayJs = toDayjs(currentDate);
    const [selectedDate, setSelecteDate] = useState<dayjs.Dayjs>(currentDayJs);

    const lines = useMemo(() => {
        return createCalendarLines(selectedDate);
    }, [selectedDate.format('YYYYMM')]);

    const years = useMemo(() => {
        return range(selectedDate.year() - 1, selectedDate.year() + 3);
    }, [selectedDate.format('YYYY')]);

    const months = useMemo(() => {
        return range(1, 12);
    }, []);

    const isCurrentDate = (date: number | undefined): boolean => {
        if (!date) {
            return false;
        }

        return currentDate.year == selectedDate.year()
            && currentDate.month == selectedDate.month() + 1
            && currentDate.date == date;
    }

    const handlePrevMonth = useCallback(() => {
        const newSelectedDate = selectedDate
            .clone()
            .add(-1, 'month')
            .startOf('month');
        setSelecteDate(newSelectedDate);
    }, [selectedDate.format('YYYYMMDD')]);

    const handleNextMonth = useCallback(() => {
        const newSelectedDate = selectedDate
            .clone()
            .add(1, 'month')
            .startOf('month');
        setSelecteDate(newSelectedDate);
    }, [selectedDate.format('YYYYMMDD')]);

    const handleChangeYear = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const newSelectedDate = selectedDate
            .clone()
            .set('year', value);
        setSelecteDate(newSelectedDate);
    }, [selectedDate.format('YYYYMMDD')]);

    const handleChangeMonth = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const newSelectedDate = selectedDate
            .clone()
            .set('month', value - 1);
        setSelecteDate(newSelectedDate);
    }, [selectedDate.format('YYYYMMDD')]);

    const handleClickCell = useCallback((date: number | undefined) => {
        if (!date) {
            return;
        }
        const newSelectedDate = selectedDate
            .clone()
            .set('date', date);
        // setSelecteDate(newSelectedDate);
        onChangeCurrentDate(toDate(newSelectedDate));
    }, [selectedDate.format('YYYYMMDD')]);

    return (
        <div css={[styles.container, floating ? styles.floating : null]}>
            <div css={styles.toolbar}>
                <button css={styles.toolbarButton} onClick={handlePrevMonth}>前月</button>
                <span>
                    <select css={styles.toolbarSelect} onChange={handleChangeYear}>
                        {years.map((year) => {
                            return (
                                <option value={year} selected={year == selectedDate.year()}>{year}</option>
                            );
                        })}
                    </select>
                                年
                                <select css={styles.toolbarSelect} onChange={handleChangeMonth}>
                        {months.map((month) => {
                            return (
                                <option value={month} selected={month == selectedDate.month() + 1}>{month}</option>
                            );
                        })}
                    </select>
                                月
                            </span>
                <button css={styles.toolbarButton} onClick={handleNextMonth}>次月</button>
            </div>
            <table css={styles.table}>
                <thead>
                    <tr>
                        <th css={commonStyles.dayOfWeek[0]}>日</th>
                        <th css={commonStyles.dayOfWeek[1]}>月</th>
                        <th css={commonStyles.dayOfWeek[2]}>火</th>
                        <th css={commonStyles.dayOfWeek[3]}>水</th>
                        <th css={commonStyles.dayOfWeek[4]}>木</th>
                        <th css={commonStyles.dayOfWeek[5]}>金</th>
                        <th css={commonStyles.dayOfWeek[6]}>土</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lines.map((line, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {
                                        line.map((cell, colIndex) => {
                                            return (
                                                <td key={colIndex}
                                                    css={isCurrentDate(cell?.date) ? [styles.cell, styles.currentDateCell] : styles.cell}
                                                    className={`day-${colIndex}`}
                                                    onClick={() => handleClickCell(cell?.date)}
                                                >
                                                    <span css={(commonStyles.dayOfWeek as any)[colIndex]}>
                                                        {cell?.date}
                                                    </span>
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
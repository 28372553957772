/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { useCallback, useMemo, useState } from "react";

const holiday = css({
    color: 'red',
});

const workday = css({
    color: 'black',
});

const saturday = css({
    color: 'blue',
});

export const commonStyles = {
    dayOfWeek: {
        0: holiday,
        1: workday,
        2: workday,
        3: workday,
        4: workday,
        5: workday,
        6: saturday,
    },
}

import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-shops-handler';
import { Shop } from '../@interfaces/shop';
import { Workspace } from '../@interfaces/workspace';
import { IWorkspaceSetting } from '../@interfaces/workspace-setting';
import { API_END_POINT } from './api';

export default function useShops(workspaceUid: string) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] = useState<IWorkspaceSetting>();
  const [shops, setShops] = useState<Shop[]>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`${API_END_POINT}/public/ws/${workspaceUid}/shops`)
    .then((response: AxiosResponse<ResponseBody>) => {
      setWorkspace(response.data.workspace);
      setWorkspaceSetting(response.data.workspaceSetting);
      setShops(response.data.shops);
    }).finally(() => {
      setLoading(false);
    });
  }, [workspaceUid]);
  return {
    workspace,
    workspaceSetting,
    shops,
    isLoadingShops: loading,
  };
}
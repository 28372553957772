import React from "react";
import sanitizeHtml from 'sanitize-html';

export const lineBreakToParagraph = (str: string) => str.split('\n').map((line) => <p>{line}</p>);

export const sanitize = (str: string) => {
    return sanitizeHtml(str, {
        allowedTags: [ 'img' ],
        allowedAttributes: {
          'img': ['src', 'width', 'height', 'style']
        },
    });
}    

export const lineBreakToParagraphAndHtml = (str: string) => str.split('\n').map((line) => <p dangerouslySetInnerHTML={{__html: sanitize(line)}} />);

export const copyToClipborad = (text: string) => {
    const tmp = document.createElement("div");
    const pre = document.createElement('pre');
    pre.style.webkitUserSelect = 'auto';
    pre.style.userSelect = 'auto';
    tmp.appendChild(pre).textContent = text;

    const s = tmp.style;
    s.position = 'fixed';
    s.right = '200%';

    document.body.appendChild(tmp);
    (document as any).getSelection().selectAllChildren(tmp);
    const result = document.execCommand("copy");
    document.body.removeChild(tmp);
};

/**
 * childの親にtargetが含まれるかどうかを判定します
 * @param child 子要素
 * @param target 対象要素
 */
export const isParent = (child: Element, target: Element): boolean => {
    const parent = child.parentElement;
    if (!parent) {
        return false;
    } else if (target == parent) {
        return true;
    } else {
        return isParent(parent, target);
    }
}

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
    target: T;
}


export const cloneElement = (srcElem: Element): HTMLElement => {
    const newElem = document.createElement(srcElem.tagName);
    for (const attr of Array.from(srcElem.attributes)) {
        newElem.setAttribute(attr.name, attr.name);
    }
    newElem.innerHTML = srcElem.innerHTML;
    return newElem;
};

export const cloneElements = (srcElems: Element[]): HTMLElement[] => {
    return Array.from(srcElems).map(cloneElement);
};

export const cloneHtmlCollection = (srcElems: HTMLCollection): HTMLElement[] => {
    return cloneElements(Array.from(srcElems));
};

export const insertUserScripts = (userScript: string, position: 'head_last' | 'body_first') => {
    try {
        const parent = document.createElement('div');
        parent.innerHTML = userScript;
        const newElems = cloneHtmlCollection(parent.children);
            for (const elem of newElems) {
                if (position == 'head_last') {
                    document.head.appendChild(elem);
                } else if (position == 'body_first') {
                    document.body.prepend(elem);
                }
            }
    } catch (e) {
        console.error(`Can't insert user scripts`, e, userScript);
    }
};

import React, { useEffect } from "react";
import { Course } from "../@interfaces/course";
import { Shop } from "../@interfaces/shop";
import { Workspace } from "../@interfaces/workspace";
import { FormResponse, FormSetting } from "../core/types/reservation-form-types";
import { DateTime, toTimeStringByTime } from "../core/types/reservation-types";
import { claerStorage } from "../pages/FormPage";

type Props = {
    workspace: Workspace | undefined,
    shop: Shop | undefined,
    course: Course | undefined,
    dateTime: DateTime,
    formSetting: FormSetting | undefined,
    formResponse: FormResponse,
}

export default function ReservationFormCompleted(props: Props) {
    const { workspace, shop, course, dateTime, formSetting, formResponse } = props;

    useEffect(() => {
        if (!workspace) {
            return;
        }
        claerStorage(workspace.uid);
    }, []);


    if (!workspace) {
        return <div>ワークスペース情報が取得できませんでした。</div>;
    }
    if (!shop) {
        return <div>店舗情報が取得できませんでした。</div>;
    }
    if (!course) {
        return <div>コース情報が取得できませんでした。</div>;
    }
    if (!formSetting) {
        return <div>予約フォーム情報が取得できませんでした。</div>;
    }

    return (
        <>
            <div className="selected-info">
                予約日時: <strong>{dateTime.date.year}年{dateTime.date.month}月{dateTime.date.date}日 {toTimeStringByTime(dateTime.time)}</strong>
            </div>
            <div className="form-message">
                下記の内容で予約を受け付けました。
            </div>
            <Form
                formSetting={formSetting}
                formResponse={formResponse}
            />
            <div className="form-message">
                この画面を閉じて終了してください。
            </div>
        </>
    );
}

type FormProps = {
    formSetting: FormSetting,
    formResponse: FormResponse,
}

function Form(props: FormProps) {
    const { formSetting, formResponse } = props;

    const fields = formSetting.fields.map((field) => {
        const fieldResponse = formResponse.fields.find((res) => res.uid == field.uid);
        const values = fieldResponse?.values.join(',');
        return (
            <div>
                {field.name}: {values}
            </div>
        );
    });

    return (
        <form className="reservation-form">
            {fields}
        </form>
    )
}

import React from 'react';
import useReactRouter from 'use-react-router';
import useCourses from '../api/use-courses';
import { NothingInfo } from '../components/NothingInfo';
import { Header } from '../components/Shared';
import useUserScript from '../hooks/use-user-script';

type PageParams = {
  workspaceUid: string,
  shopUid: string,
}

export default function ShopCoursesPage() {
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid } = match.params;

  const { workspace, workspaceSetting, shop, courses, isLoadingCourses } = useCourses(workspaceUid, shopUid || '');
  useUserScript(workspaceSetting);
  return (
    isLoadingCourses ? 
    <>
      <>Loading...</>
    </>
    :
      <>
        <Header h1={`${shop?.name}`} style={{backgroundColor: '#c21632', color: '#fff'}}></Header>
        <div className="form-message">
            コースを選択してください。
        </div>
        <div className="course-list">
          {
            courses ? 
              courses.map(((course) => {
                return (
                  <div className="course">
                    <h3 style={{color: '#c21632', fontWeight: 'bold'}}>{course.name}</h3>
                    <div className="description">
                      {course.description}
                    </div>
                    <div className="buttons">
                      <a href={`/a/${workspaceUid}/shops/${shop?.uid}/courses/${course.uid}`} style={{backgroundColor: '#c21632', color: '#fff'}}>選択する</a>
                    </div>
                  </div>
                );
              }))
              :
              <NothingInfo target={courses}>
                コースは存在しません。
              </NothingInfo>
            }
        </div>
      </>
    );
}

import { useEffect, useState } from "react";
import { IWorkspaceSetting } from "../@interfaces/workspace-setting";
import { insertUserScripts } from "../utils/browsers";

let initialized = false;

export default function useUserScript(workspaceSetting: IWorkspaceSetting | undefined) {
    useEffect(() => {
        // GA等のスクリプトの読み込み
        if (!workspaceSetting || initialized) {
            return;
        }
        if (workspaceSetting.userScriptHead) {
            insertUserScripts(workspaceSetting.userScriptHead, 'head_last');
        }
        if (workspaceSetting.userScriptBody) {
            insertUserScripts(workspaceSetting.userScriptBody, 'body_first');
        }
        initialized = true;
    }, [workspaceSetting]);
}

import React, { CSSProperties } from "react";

type HeaderProps = {
    h1: React.ReactNode;
    h2?: React.ReactNode;
    style?: CSSProperties;
}

export function Header(props: HeaderProps) {
    const { h1, h2, style } = props;
    const parts = window.location.pathname.split('/');

    const headerContent = parts[2] == 'house-ex' ?
        <>
            <img src="https://i2.wp.com/global-propertiesandservices.com/wp-content/uploads/2018/11/House-Icon.png?ssl=1" height="40"/>
            100年住宅　注文住宅の五条工務店<br />
            住宅展示場ご来場予約
        </>
        :
        <>
            <img src="/img/logo_joyfit_akajoy_rect.svg" height="40"/>
            かしこく使える24時間ジム<br />
            フィットネスジム ジョイフィット24
        </>;
    return (
        <>
            <header style={{display: 'flex'}}>
                {headerContent}
            </header>
            <h1 style={style}>
                {h1}
            </h1>
            {
                h2 ?
                <h2>
                    {h2}
                </h2>
                : null
            }
        </>
    );
}

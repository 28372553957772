import React from 'react';
import useHello from "../api/use-hello";

export default function HelloPage() {
  const { hello } = useHello();
  return (
    hello ? 
    <>
      {hello.message}<br />
      {hello.now}
    </>
    :
    <>Loading...</>
  );
}

import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-course-form-setting-handler';
import { Course } from '../@interfaces/course';
import { Shop } from '../@interfaces/shop';
import { Workspace } from '../@interfaces/workspace';
import { IWorkspaceSetting } from '../@interfaces/workspace-setting';
import { FormSetting } from '../core/types/reservation-form-types';
import { API_END_POINT } from './api';

export default function useCourseFormSetting(workspaceUid: string, shopUid: string, courseUid: string) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] = useState<IWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [course, setCourse] = useState<Course>();
  const [formSetting, setFormSetting] = useState<FormSetting>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/form-setting`)
    .then((response: AxiosResponse<ResponseBody>) => {
      setWorkspace(response.data.workspace);
      setWorkspaceSetting(response.data.workspaceSetting);
      setShop(response.data.shop);
      setCourse(response.data.course);
      setFormSetting(response.data.formSetting);
    }).finally(() => {
      setLoading(false);
    });
  }, [workspaceUid, shopUid, courseUid]);
  return {
    workspace,
    workspaceSetting,
    shop,
    course,
    formSetting,
    isLoadingCourseFormSetting: loading,
  };
}
import dayjs from 'dayjs';

type CalendarCell = {
    date: number,
}

/**
 * カレンダー用の2次元配列を作成
 */
export function createCalendarLines(currentDate: dayjs.Dayjs): (CalendarCell | null)[][] {
    var numOfMonth = currentDate.endOf('month').date()
    var daysOfMonth = Array.from({length: numOfMonth}, function(_, i) { return i + 1 });
    var firstWeekDay = currentDate.startOf('month').day();
    var data = Array.from({length: 6}).map(function(_, weekIndex) {
        return Array.from({length: 7}).map(function (_, dayIndex) {
            let i = 7 * weekIndex + dayIndex - firstWeekDay
            if (i < 0 || daysOfMonth[i] === undefined) {
                return null
            }
            const date = daysOfMonth[i];
            return {
                date,
            } as CalendarCell;
        });
    });
    // 空行の削除
    return data.filter(function(week) { 
        return week.filter(function(day) {
            return day != null;
        }).length > 0
    });
};

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import useReservationTable from '../api/use-reservation-table';
import ReservationTable from '../components/ReservationTable';
import { Header } from '../components/Shared';
import { createDateRange, DateFormat, DateRange, IDate, toDate, toDateStringByDate, toDateStringByDayjs, toDayjs } from '../core/types/reservation-types';
import useUserScript from '../hooks/use-user-script';

type PageParams = {
  workspaceUid: string,
  shopUid: string,
  courseUid: string;
}

const dafaultDateRange = {
  start: dayjs(),
  end: dayjs().add(6, 'day'),
};

export default function ShopCoursePage() {
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopUid, courseUid } = match.params;

  const searchParams = new URL(window.location.href).searchParams;
  const startDateParam = (searchParams.get('startDate') || toDateStringByDayjs(dafaultDateRange.start)) as DateFormat;
  const endDateParam = (searchParams.get('endDate') || toDateStringByDayjs(dafaultDateRange.end)) as DateFormat;

  const [dateRange, setDateRange] = useState<DateRange>(createDateRange(startDateParam, endDateParam));
  const { workspace, workspaceSetting, shop, course, reservationTable, isLoadingReservationTable } = useReservationTable(workspaceUid, shopUid || '', courseUid || '', dateRange);
  useUserScript(workspaceSetting);

  useEffect(() => {
    setDateRange(createDateRange(startDateParam, endDateParam));
  }, [startDateParam, endDateParam]);

  const handleChangeCurrentDate = (newCurrentDate: IDate) => {
    const newDateRange: DateRange = {
      start: newCurrentDate,
      end: toDate(toDayjs(newCurrentDate).add(6, 'day')),
    };
    history.push(`${document.location.pathname}?startDate=${toDateStringByDate(newDateRange.start)}&endDate=${toDateStringByDate(newDateRange.end)}`);
  };

  return (
    <>
        <Header h1={`${shop?.name}`} h2={course?.name} style={{backgroundColor: '#c21632', color: '#fff'}}></Header>
        <div className="selected-info">
          <div>
            店舗: <strong>{shop?.name}</strong>
          </div>
          <div>
            コース: <strong>{course?.name}</strong>
          </div>
        </div>
        {
          isLoadingReservationTable ? 
            <>Loading...</>
          :
            <ReservationTable
              dateRange={dateRange}
              reservationTable={reservationTable}
              onChangeCurrentDate={handleChangeCurrentDate}
            />
        }
    </>
  );
}

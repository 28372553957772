import React, { useState } from "react";
import { DeepMap, FieldError, FieldValues, RegisterOptions, useForm, UseFormMethods } from "react-hook-form";
import { useHistory } from "react-router";
import { Course } from "../@interfaces/course";
import { Shop } from "../@interfaces/shop";
import { Workspace } from "../@interfaces/workspace";
import { reserve } from "../api/use-reservation";
import { AnyField, Field, FieldResponse, FormResponse, FormSetting } from "../core/types/reservation-form-types";
import { DateTime, toTimeStringByTime } from "../core/types/reservation-types";

type Props = {
    workspace: Workspace | undefined,
    shop: Shop | undefined,
    course: Course | undefined,
    dateTime: DateTime,
    formSetting: FormSetting | undefined,
    formResponse: FormResponse,
    onChangeValue?: (field: Field, newValues: string[]) => void,
}

export default function ReservationForm(props: Props) {
    const { workspace, shop, course, dateTime, formSetting, formResponse, onChangeValue } = props;

    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);

    if (!workspace) {
        return <div>ワークスペース情報が取得できませんでした。</div>;
    }
    if (!shop) {
        return <div>店舗情報が取得できませんでした。</div>;
    }
    if (!course) {
        return <div>コース情報が取得できませんでした。</div>;
    }
    if (!formSetting) {
        return <div>予約フォーム情報が取得できませんでした。</div>;
    }

    const handleSubmitForm = (e: React.FormEvent) => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        reserve(workspace.uid, shop.uid, course.uid, dateTime, formResponse)
        .then(() => {
            window.location.href = `thanks${window.location.search}`;
        }).catch((e) => {
            window.alert('予約の登録に失敗しました。');
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <>
            <div className="selected-info">
                予約日時: <strong>{dateTime.date.year}年{dateTime.date.month}月{dateTime.date.date}日 {toTimeStringByTime(dateTime.time)}</strong>
            </div>
            <div className="form-message">
               お客様の情報を入力してください。
            </div>
            <Form
                formSetting={formSetting}
                formResponse={formResponse}
                onChangeValue={onChangeValue}
                onSubmitForm={handleSubmitForm}
                submitting={submitting}
            />
        </>
    );
}

type FormProps = {
    formSetting: FormSetting,
    formResponse: FormResponse,
    onChangeValue?: (field: Field, newValues: string[]) => void,
    onSubmitForm?: (e: React.FormEvent) => void,
    submitting: boolean,
}

function Form(props: FormProps) {
    const { formSetting, formResponse, onChangeValue, onSubmitForm, submitting } = props;
    const validationContext = useForm();
    const { handleSubmit, errors } = validationContext;
    console.log('errors', errors);

    const buildFiled = (field: AnyField) => {
        const fieldResponse: FieldResponse = formResponse.fields.find((value) => {
            return value.uid == field.uid;
        }) || {
            uid: field.uid,
            values: [],
        };
        if (field.type == 'text') {
            return <TextFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } else if (field.type == 'textarea') {
            return <TextareaFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } else if (field.type == 'radio') {
            return <RadioFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } else if (field.type == 'checkbox') {
            return <CheckboxFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } else if (field.type == 'tel') {
            return <TelFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } else if (field.type == 'email') {
            return <EmailFormField key={field.uid} field={field} fieldResponse={fieldResponse} onChangeValue={onChangeValue} validationContext={validationContext} />;
        } 
    };

    const fields = formSetting.fields.map((field) => {
        return buildFiled(field);
    });

    return (
        <form onSubmit={onSubmitForm ? handleSubmit(onSubmitForm) : undefined} className="reservation-form">
            {fields}
            <button
                type="submit"
                style={{color: '#fff', background: submitting ? '#777' : 'rgb(194, 22, 50)'}}
                disabled={submitting}
            >予約する</button>
        </form>
    )
}

const buildFieldValidation = (field: Field): RegisterOptions => {
    return { required: true };
};

type FieldErrorMessageProps = {
    field: Field,
    errors: DeepMap<FieldValues, FieldError>,
}

function FieldErrorMessage(props: FieldErrorMessageProps) {
    const { field, errors } = props;

    const fieldError = errors[field.uid];
    if (!fieldError) {
        return null;
    }

    const message = fieldError.type == 'required' ? '必須です。' : '';

    return (
        <div style={{color: 'red'}}>
            {message}
        </div>
    );
};

type FormFieldProps = {
    field: Field,
    fieldResponse: FieldResponse,
    onChangeValue?: (field: Field, newValues: string[]) => void,
    validationContext: UseFormMethods<FieldValues>,
}

type TextFormFieldProps = FormFieldProps & {
}

function TextFormField(props: TextFormFieldProps) {
    const { field, fieldResponse, onChangeValue, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChangeValue?.(field, [newValue]);
    }

    return (
        <div>
            <input
                type="text"
                name={field.uid}
                value={fieldResponse.values[0]}
                placeholder={field.name}
                onChange={handleChangeValue}
                ref={register(fieldValidation)}
            />
            <FieldErrorMessage field={field} errors={errors} />
        </div>
    )
}

type TextareaFormFieldProps = FormFieldProps & {
}

function TextareaFormField(props: TextareaFormFieldProps) {
    const { field, fieldResponse, onChangeValue, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        onChangeValue?.(field, [newValue]);
    }

    return (
        <div>
            <textarea
                name={field.uid}
                value={fieldResponse.values[0]}
                placeholder={field.name}
                onChange={handleChangeValue}
                ref={register(fieldValidation)}
            ></textarea>
            <FieldErrorMessage field={field} errors={errors} />
        </div>
    )
}

type RadioFormFieldProps = FormFieldProps & {
}

function RadioFormField(props: RadioFormFieldProps) {
    const { field, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    return (
        <div>
            TODO
            <FieldErrorMessage field={field} errors={errors} />
        </div>
    )
}

type CheckboxFormFieldProps = FormFieldProps & {
}

function CheckboxFormField(props: CheckboxFormFieldProps) {
    const { field, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    return (
        <div>
            TODO
            <FieldErrorMessage field={field} errors={errors} />
        </div>
    )
}

type TelFormFieldProps = FormFieldProps & {
}

function TelFormField(props: TelFormFieldProps) {
    const { field, fieldResponse, onChangeValue, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChangeValue?.(field, [newValue]);
    }

    return (
        <div>
            <input
                type="tel"
                name={field.uid}
                value={fieldResponse.values[0]}
                placeholder={field.name}
                onChange={handleChangeValue}
                ref={register(fieldValidation)}
            />
            <FieldErrorMessage field={field} errors={errors} />
        </div>
    )
}

type EmailFormFieldProps = FormFieldProps & {
}

function EmailFormField(props: EmailFormFieldProps) {
    const { field, fieldResponse, onChangeValue, validationContext } = props;
    const { errors, register } = validationContext;

    const fieldValidation = buildFieldValidation(field);

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChangeValue?.(field, [newValue]);
    }

    return (
        <div>
             <input
                type="tel"
                name={field.uid}
                value={fieldResponse.values[0]}
                placeholder={field.name}
                onChange={handleChangeValue}
                ref={register(fieldValidation)}
            />
            <FieldErrorMessage field={field} errors={errors} />
       </div>
    )
}
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-course-handler';
import { Course } from '../@interfaces/course';
import { Shop } from '../@interfaces/shop';
import { Workspace } from '../@interfaces/workspace';
import { FormResponse } from '../core/types/reservation-form-types';
import { DateTime } from '../core/types/reservation-types';
import { API_END_POINT } from './api';

export function reserve(workspaceUid: string, shopUid: string, courseUid: string, dateTime: DateTime, formResponse: FormResponse) {
  const data = {
    dateTime,
    formResponse,
  };
  return axios.post(`${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservations`, data);
}
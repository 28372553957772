import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import FormPage from './pages/FormPage';
import HelloPage from './pages/HelloPage';
import ShopCoursePage from './pages/ShopCoursePage';
import ShopCoursesPage from './pages/ShopCoursesPage';
import WorkspacePage from './pages/WorkspacePage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/hello" component={HelloPage} exact />
        <Route path="/a/:workspaceUid/" component={WorkspacePage} exact />
        <Route path="/a/:workspaceUid/shops/:shopUid" component={ShopCoursesPage} exact />
        <Route path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid" component={ShopCoursePage} exact />
        <Route path="/a/:workspaceUid/shops/:shopUid/courses/:courseUid/:action(form|thanks)" component={FormPage} exact />
      </Switch>
    </Router>
  );
}

export default App;

import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { DateRange, ReservationTable, toDateStringByDate } from '../core/types/reservation-types';
import { ResponseBody } from '../../../frontend-api/src/handlers/public/public-reservation-table-handler';
import { Course } from '../@interfaces/course';
import { Shop } from '../@interfaces/shop';
import { Workspace } from '../@interfaces/workspace';
import { API_END_POINT } from './api';
import { IWorkspaceSetting } from '../@interfaces/workspace-setting';

export default function useReservationTable(workspaceUid: string, shopUid: string, courseUid: string, range: DateRange) {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceSetting, setWorkspaceSetting] = useState<IWorkspaceSetting>();
  const [shop, setShop] = useState<Shop>();
  const [course, setCourse] = useState<Course>();
  const [reservationTable, setReservationTable] = useState<ReservationTable>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const params = {
      startDate: toDateStringByDate(range.start),
      endDate: toDateStringByDate(range.end),
    };
    setLoading(true);
    axios.get(`${API_END_POINT}/public/ws/${workspaceUid}/shops/${shopUid}/courses/${courseUid}/reservation-table`, {
      params,
    }).then((response: AxiosResponse<ResponseBody>) => {
      setWorkspace(response.data.workspace);
      setWorkspaceSetting(response.data.workspaceSetting);
      setShop(response.data.shop);
      setCourse(response.data.course);
      setReservationTable(response.data.reservationTable);
    }).finally(() => {
      setLoading(false);
    });
  }, [workspaceUid, shopUid, courseUid, JSON.stringify(range)]);
  return {
    workspace,
    workspaceSetting,
    shop,
    course,
    reservationTable,
    isLoadingReservationTable: loading,
  };
}
import { time } from "console"
import dayjs, { Dayjs } from "dayjs"

export type IDate = {
    year: number,
    month: number,
    date: number,
}

export type DateRange = {
    start: IDate,
    end: IDate,
}

export type OptionalDateRange = {
    start?: IDate,
    end?: IDate,
}

export type Time = {
    hour: number,
    minute: number,
}

export type DateTime = {
    date: IDate,
    time: Time,
}

// 9:00 -> 540の表現
export type TimeValue = number;

export type TimeRange = {
    start: Time,
    end: Time,
}

export type Slot = {
    timeRange: TimeRange,
}

export type Capacity = {
    total: number,
}

export type SlotCapacity = {
    slot: Slot,
    capacity: Capacity,
}

export type Reserved = {
    total: number,
}

export type ReservedSlotCapacity = SlotCapacity & {
    reserved: Reserved,
}

export type ReservedReservation = {
    date: IDate,
    time: Time,
    total: number,
}

export type DateSlotCapacity = {
    date: IDate,
    slots: SlotCapacity[],
}

export type DateReservedSlotCapacity = {
    date: IDate,
    slots: ReservedSlotCapacity[],
}

export type ReservationCapacity = {
    dateRange: DateRange,
    dates: DateSlotCapacity[],
}

export type ReservationTable = {
    dateRange: DateRange,
    dates: DateReservedSlotCapacity[],
}

export type Monday = 'Monday';
export type Tuesday = 'Tuesday';
export type Wednesday = 'Wednesday';
export type Thursday = 'Thursday';
export type Friday = 'Friday';
export type Saturday = 'Saturday';
export type Sunday = 'Sunday';

export type DayOfWeek = Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday;

export type WeeklyCondition = {
    type: 'weekly',
    dayOfWeeks: DayOfWeek[],
}

export type ScheduleCondition = WeeklyCondition;

export type Schedule = {
    condition: ScheduleCondition,
    capacity: Capacity,
    timeRanges: TimeRange[],
}

export type RegularRule = {
    type: 'regular',
    dateRange: OptionalDateRange,
    schedules: Schedule[],
}

export type HolidayRule = {
    type: 'holiday',
    dateRange: DateRange,
}

export type SlotRule = RegularRule | HolidayRule;

export type SlotSetting = {
    unit: number,
    rules: SlotRule[],
}

/**
 * 
 * @param day 0-6 */
export function toDayOfWeek(day: number): DayOfWeek {
    if (day == 0) {
        return 'Sunday';
    } else if (day == 1) {
        return 'Monday';
    } else if (day == 2) {
        return 'Tuesday';
    } else if (day == 3) {
        return 'Wednesday';
    } else if (day == 4) {
        return 'Thursday';
    } else if (day == 5) {
        return 'Friday';
    } else if (day == 6) {
        return 'Saturday';
    } else {
        throw `Invalid dayOfWeek value: value=${day}`;
    }
}

export type DateFormat = `${number}-${number}-${number}`;
export type TimeFormat = `${number}:${number}`;
export type DateTimeFormat = `${DateFormat} ${TimeFormat}`;

export function createDateTime(dateTimeString: DateTimeFormat): DateTime {
    const [date, time] = dateTimeString.split(' ');
    return {
        date: createDate(date as DateFormat),
        time: createTime(time as TimeFormat),
    };
}

export function createDate(dateString: DateFormat): IDate {
    const [year, month, date] = dateString.split('-');
    return {
        year: parseInt(year),
        month: parseInt(month),
        date: parseInt(date),
    };
}

export function createDateRange(startDateString: DateFormat, endDateString: DateFormat): DateRange {
    const start = createDate(startDateString);
    const end = createDate(endDateString);
    return {
        start,
        end,
    };
}

export function createTime(timeString: TimeFormat): Time {
    const [hour, minute] = timeString.split(':');
    return {
        hour: parseInt(hour),
        minute: parseInt(minute),
    };
}

export function toDayjs(date: IDate): Dayjs {
    return dayjs().year(date.year).month(date.month == 0 ? 12 : date.month - 1).date(date.date).startOf('date');
}

export function toDate(day: Dayjs): IDate {
    return {
        year: day.year(),
        month: day.month() + 1,
        date: day.date(),
    };
}

export function toDateStringByDayjs(day: Dayjs): string {
    return day.format('YYYY-MM-DD');
}

export function toDateStringByDate(day: IDate): string {
    const dayjsDate = toDayjs(day);
    return toDateStringByDayjs(dayjsDate);
}

export function toDateTimeString(date: IDate, time: Time): DateTimeFormat {
    return `${toDateStringByDate(date)} ${toTimeStringByTime(time)}` as DateTimeFormat;
}

function zeroPadding(str: string, digit: number) {
    const paddingLength = digit - str.length;
    if (paddingLength <= 0) {
        return str;
    }
    return `${'0'.repeat(paddingLength)}${str}`
}

export function toTimeStringByTime(time: Time): TimeFormat {
    return `${time.hour}:${zeroPadding(time.minute.toString(), 2)}` as TimeFormat;
}

export function toTimeStringByTimeNumber(timeNumber: number): TimeFormat {
    return toTimeStringByTime(toTimeByTimeNumber(timeNumber));
}

export function toTimeByTimeNumber(timeNumber: number): Time {
    return {
        hour: Math.floor(timeNumber / 60),
        minute: timeNumber % 60,
    }
}

export function toDates(range: DateRange): IDate[] {
    const { start, end } = range;
    const startDay = toDayjs(start);
    const endDay = toDayjs(end);

    const dates: IDate[] = [];
    let currentDay = startDay;
    while(currentDay.isAfter(endDay, 'date') == false) {
        const date = toDate(currentDay);
        dates.push(date);
        currentDay = currentDay.add(1, 'd');
    }
    return dates;
}

export function range(from: number, to: number): number[] {
    const result = [];
    for (let i = from; i <= to; i++) {
        result.push(i);
    }
    return result;
}

export function includesDate(range: OptionalDateRange | undefined, date: IDate): boolean {
    if (!range) {
        return true;
    } else if (!range.start && range.end) {
        return toDayjs(date).unix() <= toDayjs(range.end).unix();
    } else if (range.start && !range.end) {
        return toDayjs(range.start).unix() <= toDayjs(date).unix();
    } else if (range.start && range.end) {
        return toDayjs(date).unix() <= toDayjs(range.end).unix()
            && toDayjs(range.start).unix() <= toDayjs(date).unix();
    } else {
        return true;
    }
}

export function matchSchedule(condition: ScheduleCondition, date: IDate): boolean {
    if (condition.type == 'weekly') {
        return matchInDayOfWeeks(condition.dayOfWeeks, date);
    } else {
        return false;
    }
}

export function matchInDayOfWeeks(dayOfWeeks: DayOfWeek[], date: IDate): boolean {
    const targetDate = toDayjs(date);
    return dayOfWeeks.includes(toDayOfWeek(targetDate.day()));
}

export function toTimeValue(time: Time): TimeValue {
    return time.hour * 60 + time.minute;
}

export function toTimeByTimeValue(timeValue: TimeValue): Time {
    return {
        hour: Math.floor(timeValue / 60),
        minute: timeValue % 60,
    }
}

export function uniqueTimes(times: Time[]) {
    return [...new Set(times.map(toTimeValue))].sort((a, b) => a < b ? -1 : 1).map(toTimeByTimeValue);
}

export const dayOfWeeksLabel: { [key: number]: string; } = {
    0: '日',
    1: '月',
    2: '火',
    3: '水',
    4: '木',
    5: '金',
    6: '土',
};
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { API_END_POINT } from './api';

interface HelloData {
  message: string;
  now: string;
}

export default function useHello() {
  const [hello, setHello] = useState<HelloData>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`${API_END_POINT}/public/hello`)
    .then((response: AxiosResponse<HelloData>) => {
      setHello(response.data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);
  return {
    hello: hello,
    isLoadingHello: loading,
  };
}
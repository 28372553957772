import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { DateRange, DateReservedSlotCapacity, dayOfWeeksLabel, IDate, ReservationTable as IReservationTable, toDates, toDateStringByDate, toDateStringByDayjs, toDayjs, toTimeStringByTimeNumber } from "../core/types/reservation-types";
import { isParent } from "../utils/browsers";
import { Calendar } from "./calendar/Calendar";

type Props = {
    reservationTable: IReservationTable | undefined,
    dateRange: DateRange,
    onChangeCurrentDate: (newCurrentDate: IDate) => void,
}

export default function ReservationTable(props: Props) {
    const { dateRange, reservationTable, onChangeCurrentDate } = props;

    const [visibleCalendar, setVisibleCalendar] = useState(false);
    const calendarContainerEl = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handler = (e: any) => {
            if (!calendarContainerEl?.current) {
                return;
            }
            const child = e.target as HTMLElement;
            if (isParent(e.target, calendarContainerEl.current)) {
                return;
            }
            setVisibleCalendar(false);
        };
        document.body.addEventListener('click', handler, false);
        return () => {
            document.body.removeEventListener('click', handler, false);
        };
    }, []);

    const startDate = toDayjs(dateRange.start);
    const endDate = toDayjs(dateRange.end);

    if (!reservationTable) {
        return <div>予約情報が取得できませんでした。</div>;
    }

    const dayCount = reservationTable.dates.length;
    const prevUrl = `?startDate=${toDateStringByDayjs(startDate.add(-dayCount, 'day'))}&endDate=${toDateStringByDayjs(endDate.add(-dayCount, 'day'))}`;
    const nextUrl = `?startDate=${toDateStringByDayjs(startDate.add(dayCount, 'day'))}&endDate=${toDateStringByDayjs(endDate.add(dayCount, 'day'))}`;

    const handleClickShowCalendar = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setVisibleCalendar(true);
    };

    return (
        <>
            <div className="form-message">
                ご都合のよい日時を選択してください。
            </div>
            <div style={{padding: "20px 20px 0px", textAlign: "center"}}>
                <Link to={prevUrl} style={{float: "left"}}>
                    <img src="https://totoco.biz/asp//img/web/ic2.png" />&nbsp;前の週へ
                </Link>
                <Link to={nextUrl} style={{float: "right"}}>次の週へ&nbsp;<img src="https://totoco.biz/asp//img/web/ic1.png" /></Link>
                <span className="calendar-trigger" style={{position: 'relative'}}>
                    <img src="https://totoco.biz/asp//assets/img/icons/calendar.png" width="20" />
                    <a href="javascript:void(0)" className="chooser-trigger" onClick={handleClickShowCalendar}>日付指定で移動</a>
                    <div ref={calendarContainerEl} style={{position: 'relative'}}>
                        {
                            visibleCalendar ?
                            <Calendar currentDate={dateRange.start} onChangeCurrentDate={onChangeCurrentDate} floating={true} />
                            :
                            null
                        }
                    </div>
                </span>
            </div>
            <Table reservationTable={reservationTable} />
        </>
    );
}

type TableProps = {
    reservationTable: IReservationTable,
}

function Table(props: TableProps) {
    const { reservationTable } = props;

    return (
        <table className="reservation-table">
            <Thead reservationTable={reservationTable} />
            <Tbody reservationTable={reservationTable} />
        </table>
    )
}

type TheadProps = {
    reservationTable: IReservationTable,
}

type DayCounter = {
    month: number,
    count: number,
}

/**
 * 月ごとに日付をカウントした集計結果を返します
 * @param dates 
 */
function countDayByMonth(dates: IDate[]): DayCounter[] {
    const months = dates.map((date) => date.month).reduce((prev, current) => {
        let targetGroup = prev.find((counter) => {
            return counter.month == current;
        });
        if (!targetGroup) {
            targetGroup = {
                month: current,
                count: 0,
            };
            prev.push(targetGroup);
        }
        targetGroup.count = targetGroup.count + 1;
        return prev;
    }, [] as DayCounter[]);
    return months;
}

function Thead(props: TheadProps) {
    const { reservationTable } = props;

    const dates = toDates(reservationTable.dateRange);
    const months = countDayByMonth(dates);
    const days = dates.map((date) => {
        return new Date(date.year, date.month - 1, date.date).getDay();
    })
    return (
        <thead>
            <tr>
                <th rowSpan={3}>予約<br />時間</th>
                {
                    months.map((month) => {
                        return (
                            <th colSpan={month.count}>{month.month}月</th>
                        );
                    })
                }
            </tr>
            <tr className="date">
                {
                    dates.map((date, index) => {
                        return (
                            <th className={`day-${days[index]}`}>{date.date}</th>
                        )
                    })
                }
            </tr>
            <tr className="day-of-week">
                {
                    dates.map((date, index) => {
                        return (
                            <th className={`day-${days[index]}`}>{dayOfWeeksLabel[days[index]]}</th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}

type TbodyProps = {
    reservationTable: IReservationTable,
}

function Tbody(props: TbodyProps) {
    const { reservationTable } = props;

    const allTimes = reservationTable.dates.flatMap((date) => {
        return date.slots.map((slot) => {
            return slot.slot.timeRange.start.hour * 60 + slot.slot.timeRange.start.minute;
        })
    });
    const times = Array.from(new Set(allTimes)).sort((a, b) => {
        return a < b ? -1 : 1;
    });

    return (
        <tbody>
            {
                times.map((time) => {
                    return (
                        <tr>
                            <th>{toTimeStringByTimeNumber(time)}</th>
                            {
                                reservationTable.dates.map((date) => {
                                    return (
                                        <Td time={time} date={date} />
                                    );
                                })
                            }
                        </tr>
                    );
                })
            }
        </tbody>
    )
}

type TdProps = {
    time: number,
    date: DateReservedSlotCapacity,
}

function Td(props: TdProps) {
    const { time, date } = props;

    const url = `${window.location.pathname}/form?date=${toDateStringByDate(date.date)}&time=${toTimeStringByTimeNumber(time)}`;
    const slot = date.slots.find((slot) => {
        const currentTime = slot.slot.timeRange.start.hour * 60 + slot.slot.timeRange.start.minute;
        return time == currentTime;
    });
    if (slot && slot.capacity.total > slot.reserved.total) {
        return (
            <td><a href={`${url}`}><img src="/img/available.png" width="20"/></a></td>
        );
    } else {
        return (
            <td><a>×</a></td> 
        );
    }
}

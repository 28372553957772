import React from 'react';
import useReactRouter from 'use-react-router';
import useShops from '../api/use-shops';
import { NothingInfo } from '../components/NothingInfo';
import { Header } from '../components/Shared';
import useUserScript from '../hooks/use-user-script';

type PageParams = {
  workspaceUid: string;
}

export default function WorkspacePage() {
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;

  const { workspace, workspaceSetting, shops, isLoadingShops } = useShops(workspaceUid);
  useUserScript(workspaceSetting);
  return (
    isLoadingShops ? 
    <>
      <>Loading...</>
    </>
    :
      <>
        <Header h1={workspace?.name} style={{backgroundColor: '#c21632', color: '#fff'}}></Header>
        <div className="form-message">
            店舗を選択してください。
        </div>
        <div className="shop-list">
          {
            shops ?
              shops.map(((shop) => {
                return <div className="shop"><a href={`/a/${workspaceUid}/shops/${shop.uid}`}>{shop.name}</a></div>
              }))
            :
            <NothingInfo target={shops}>
              店舗は存在しません。
            </NothingInfo>
          }
        </div>
      </>
    );
}
